import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';

export const BoxStyled = styled(HorizontalView)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    margin-top: 12.8vw;
  }
`;

export const LeftSideWrapper = styled(VerticalView)`
  max-width: 50%;
  @media (max-width: 1100px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const Title = styled.p`
  font-size: 32px;
  font-weight: 700;
  font-family: Inter, sans-serif;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 6.4vw;
    margin-bottom: 4.267vw;
  }
`;

export const DescriptionText = styled.p`
  color: #8f96a3;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    font-size: 4.267vw;
    margin-bottom: 8.533vw;
  }
`;

export const Socialsrapper = styled(HorizontalView)`
  gap: 8px;
  margin-right: 20px;
  @media (max-width: 768px) {
    gap: 3.2vw;
    margin-right: 3.2vw;
  }
`;

export const TelegramWrapper = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 240px;
  height: 48px;
  background-color: #fff;
  justify-content: center;
  gap: 10px;
  border-radius: 12px;
  &:hover {
    background: rgba(255, 255, 255, 0.95);
  }
  &:active {
    background: rgba(255, 255, 255, 0.9);
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5.333vw;
    height: 12.267vw;
  }
`;

export const TelegramText = styled.p`
  color: #0a0e13;

  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 4.267vw;
  }
`;

export const MembersText = styled.p`
  color: #c4f538;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  @media (max-width: 768px) {
    font-size: 4.267vw;
  }
`;

export const RightSideWrapper = styled(VerticalView)`
  background-color: #12161b;
  padding: 0 28px;
  max-width: 50%;
  border-radius: 20px;
  @media (max-width: 1100px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const CalendarTitle = styled.p`
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-weight: 800;
    font-size: 3.733vw;
  }
`;

export const CalendarList = styled.ul``;

export const CalendarItem = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;
  border-top: 1px solid #1f2630;
  padding: 8px 0;
  @media (min-width: 768px) {
    height: clamp(0px, 3.889vw, 56px);
  }
`;

export const CalendarDay = styled.p`
  color: #717274;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 140%;
  min-width: 36px;
  @media (max-width: 768px) {
    font-size: 3.467vw;
    min-width: 9.6vw;
  }
`;

export const CalendarDayInfo = styled.p`
  color: #fff;

  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 140%;

  @media (max-width: 768px) {
    font-size: 4.267vw;
  }
`;

export const SocialLinkStyled = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e2227;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 8px;

  &:hover {
    background-color: #262a2f;
  }
  &:active {
    background-color: #1a1e23;
  }

  @media (max-width: 768px) {
    width: 12.8vw;
    height: 12.8vw;
    padding: 1.333vw;
    border-radius: 2.133vw;
  }
`;
