import { useDeviceType } from 'src/hooks/useDeviceType';
import { Header } from '../Main/components/Header';
import { HeaderDesktop } from '../Main/components/HeaderDesktop';
import { Container, Wrapper } from '../../components/Container/styled';
import styled from 'styled-components';
import { useEffect } from 'react';
import { analytics } from 'src/integrations/firabse';
import { logEvent } from 'firebase/analytics';

const appRelease = './Haqabi Wallet (1.18).apk';

const Text = styled.p`
  color: #fff;
  font-size: 3.6vw;
  text-align: center;
  letter-spacing: 0.107vw;
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  margin-top: 10.667vw;
  @media (min-width: 769px) {
    font-size: 3.333vw;
    letter-spacing: 0.028vw;
    margin-top: 4.444vw;
  }
`;

export const DownloadApk = () => {
  const { isMobile } = useDeviceType();
  useEffect(() => {
    const timeout = setTimeout(() => {
      logEvent(analytics, 'download_apk', {
        message: 'download_apk',
      });
      const link = document.createElement('a');
      link.href = appRelease;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Wrapper>
      <Container isMain>
        {isMobile ? <Header isMain /> : <HeaderDesktop isMain />}
        <Text>Please wait, the download will start soon.</Text>
      </Container>
    </Wrapper>
  );
};
